.header {
  background-color: white;
  height: 60px;
  padding: 10px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
}
.table {
  height: calc(100vh - 25vh);
  overflow-y: auto;
  margin: 2% 10%;
  background-color: rgba(255, 255, 255, 1);
  padding: 1px 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.item-date {
  width: 48%;
}
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  background-color: #fafafa;
  cursor: pointer;
}
.upload .anticon {
  font-size: 24px;
  color: #1890ff;
}
.upload div {
  margin-top: 8px;
  font-size: 14px;
  color: #666;
}
.placeholdersection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}
.placeholderbutton {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 2px solid rgba(230, 247, 255, 1);
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}
.placeholder {
  width: 100%;
  height: 400px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.closeicon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  z-index: 1;
  transition: color 0.3s ease;
}
.closeicon:hover {
  color: red;
}
.placeholderbutton.empty {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
}
.placeholderbutton.filled {
  width: 50%;
  height: 400px;
}
.placeholderimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.placeholderupload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1890ff;
}
.placeholdericon {
  color: #1890ff;
  font-size: 60px;
  margin-bottom: 8px;
}
.placeholdertext {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin: 0;
}
