/** Scrollable **/
.custom_scrollbar {
  scrollbar-color: #aab2bf #d9d9d9;
  scrollbar-width: thin;
}
.custom_scrollbar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #aab2bf !important;
  border-radius: 10px !important;
}
.custom_scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #d9d9d9 !important;
  border-radius: 10px !important;
  background: #d7d7d7 !important;
}
.vis-vertical-scroll {
  scrollbar-color: #aab2bf #d9d9d9;
  scrollbar-width: thin;
}
.vis-vertical-scroll::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.vis-vertical-scroll::-webkit-scrollbar-thumb {
  background: #aab2bf !important;
  border-radius: 10px !important;
}
.vis-vertical-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #d9d9d9 !important;
  border-radius: 10px !important;
  background: #d7d7d7 !important;
}
/** end scroll **/
/** custom antd table scrollbar */
.ant-table-body {
  overflow: auto !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #d9d9d9;
  border-radius: 10px;
  background: #d7d7d7;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #aab2bf;
  border-radius: 10px;
}
/** end custom antd table scrollbar */
.app-upload-list-item:hover {
  background-color: #D9D9D9;
}
/** global custom antd */
.ant-form-item {
  margin-bottom: 10px;
}
.team-shifts .ant-form-item-label {
  font-weight: 600;
}
.team-modal .ant-modal-footer {
  margin-top: 0;
}
.ant-table-row-first {
  width: 100%;
}
/** end global custom antd */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.rowSelection {
  cursor: pointer;
}
