.header {
  background-color: white;
  height: 60px;
  padding: 10px 10px 10px 20px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
}
.table {
  padding: 10px;
}

.tableheader {
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  margin-top: 5px;
  align-items: center;
  gap: 1%;
  border-radius: 5px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 5px;
}
.date {
  min-width: 100px;
}

.portoneinput {
  min-width: 100px;
  margin-left: 10px;
  width: 300px;
  border: 1px solid #d9d9d9;
}
.portonebutton {
  width: 120px;
  background-color: rgba(24, 144, 255, 1);
  color: white;
  border-radius: 6px;
}

.portonebutton .text {
  display: inline;
}

.portonebutton .icon {
  display: inline-flex;
  margin-right: 8px;
}

@media (max-width: 800px) {
  .portonebutton .text {
    display: none;
  }
  .portonebutton {
    width: 50px;
    background-color: rgba(24, 144, 255, 1);
    color: white;
    border-radius: 6px;
  }
}
.exportbutton {
  margin-right: 10px;
  width: 130px;
  background-color: rgba(24, 144, 255, 1);
  color: white;
  border-radius: 6px;
}

.exportbutton .text {
  display: inline; /* Mặc định hiển thị văn bản */
}

.exportbutton .icon {
  display: inline-flex; /* Mặc định hiển thị biểu tượng */
  margin-right: 8px; /* Khoảng cách giữa biểu tượng và văn bản */
}

@media (max-width: 800px) {
  .exportbutton .text {
    display: none; /* Ẩn văn bản khi chiều rộng nhỏ hơn 800px */
  }
  .exportbutton {
    width: 60px;
    background-color: rgba(24, 144, 255, 1);
    color: white;
    border-radius: 6px;
  }
}

.nhombuttonchoose {
  gap: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedRow {
  background-color: #7aebff8f !important; /* Light blue background */
}

h3 {
  margin-top: 0;
}
.customSelect {
  min-width: 70px;
  width: 190px;
}
.tablelast {
  max-width: 100%;
  //overflow-y: auto;
  //border-radius: 5px;
  background-color: white;
}
.portonetotal {
  margin-left: 10px;
  font-size: 16px;
  height: 30px;
}
.customdrawer {
  height: 100%;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 5px;
}
.formwrapper {
  height: 100vh-35px;
  overflow-y: auto;
}
.inputitem,
.textareaitem {
  width: 100%;
  border-radius: 5px;
}
.imageitem {
  border-radius: 5px;
}
.submitbutton {
  background-color: rgba(24, 144, 255, 1);
  color: white;
  border: none;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
}
.buttoncontainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
h3 {
  font-size: 20px;
}
.formitemwrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  justify-content: space-between;
  height: calc('100vh - 100px');
}
.formitemlabel {
  flex: 1;
  margin-right: 10px;

  display: flex;
  flex-direction: column;
}
.formiteminput {
  flex: 1;
}
.customModal {
  max-width: 350px;
}
.formitem {
  margin: 0px;
}
