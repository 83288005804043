.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 10px;
}

.headerLeft {
  // width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 670px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerLeft {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
  }
}
