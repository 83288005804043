.header {
  background-color: white;
}
.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
}
.cardWrapper {
  margin-bottom: 20px;
}
.statuscar {
  padding: 0px 44px 0px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card {
  height: calc(100vh - 120px);
  overflow-y: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.suffix {
  color: black;
  padding: 0 8px;
}
.input{
  height: 70px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .imageWrapper,
  .inputWrapper {
    max-width: 100%;
    padding: 15px;
  }
}

.button {
  color: white;
  background-color: #1890ff;
  width: 150px;
  font-size: 14px;
  border-radius: 5px;
}
.commonTextStyle {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  display: flex;
}

@media (max-width: 767px) {
  .card {
    width: 100%;
  }
}
.imageCol {
  display: flex;
  justify-content: center;
}

@media (max-width: 576px) {
  .imageCol {
    width: 100%;
    text-align: center;
  }

  .antimage {
    width: 100%;
    height: 50px;
  }
}

.DateButtonGroup {
  display: flex;
  flex-wrap: wrap;
}
.rockbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ButtonOption {
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  height: auto;
  flex-wrap: wrap;
  width: 100px;
}
.selectedButton {
  background-color: #1890ff;
  color: white;
}
