.mainContainer {
  flex: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectOrgContainer {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerContainer {
  width: 77%;
  height: auto;
  display: flex;
  padding-block: 10px;
  padding-top: 30px;
}

.bodyContainer {
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.scrollAble::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.scrollAble::-webkit-scrollbar
{
    width: 4px;
    height: 4px;
    background-color: #F5F5F5 !important;
}

.scrollAble::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2);
}