.header {
  height: 50px;
  background-color: white;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
}

.ProjectItem {
  max-height: 100vh;
  overflow-y: auto;
  min-width: 300px;
  padding: 20px;
  position: relative;
}

.topper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 3px solid #f5f5f5;
}
.cardWrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 430px;
  .viewDetailsButton {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #524d4d15;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px 8px;
    color: #f9f5f5;
  }

  &:hover .viewDetailsButton {
    display: block;
  }
}
@media only screen and (max-width: 755px) {
  .topper {
    flex-direction: column;
    gap: 10px;
  }
  .inputSearch {
    width: 100%;
  }
}
