.header {
  background-color: white;
  height: 100px;
  padding: 10px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .header {
    height: 120px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.table {
  padding: 20px;
}
.tableheader {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}
.buttontotal {
  color: white;
  background-color: #007bff;
}
.DateButtonGroup {
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  width: 300px;
  height: 32px;
}
.DateButtonOption {
  flex: 1;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
  color: rgba(9, 109, 217, 1);
  background-color: rgba(230, 247, 255, 1);
}
.DateButtonOption.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
.DateButtonOption:hover {
  background-color: rgba(9, 109, 217, 1);
  color: rgba(255, 255, 255, 1);
}
.tablelast {
  height: calc(100vh - 190px);
  overflow-y: auto;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}
.Dashboardtotal {
  font-size: 16px;
  height: 50px;
}
.card-title {
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
}
.chartContainer {
  transition: box-shadow 0.3s ease;
}
.chartContainer:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.detailInfo:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
