.uploadcontainer {
  margin: 0 auto;
  border: 1px dashed #d9d9d9 ;
  text-align: center;
  box-sizing:border-box ;
  padding: 20px;
  width: 470px;
}
.customOkButton {
  width: 110px; 
  height: 35px;
}