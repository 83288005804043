.orgCardConatiner {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  & .descriptionsConatainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: #595959;
  }

  & .imgContainer {
    width: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .orgInfoContainer {
    flex: 1;
    color: #595959;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &:hover {
    transition: all 400ms ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ced5e0;
  }

  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
