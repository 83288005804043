/** Scrollable **/
@bg-color-scroll: #aab2bf;
@border-radius-scroll: 10px;
@bg-color-scroll-2: #d9d9d9;
@bg-color-scoll-track: #d7d7d7;

.scroll-custom-width(@width) {
    &::-webkit-scrollbar {
      width: @width !important;
      height: @width !important;
    }
    &::-webkit-scrollbar-thumb {
      background: @bg-color-scroll !important;
      border-radius: @border-radius-scroll !important;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 @border-radius-scroll @bg-color-scroll-2 !important;
      border-radius: @border-radius-scroll !important;
      background: @bg-color-scoll-track !important;
    }
    scrollbar-color: @bg-color-scroll @bg-color-scroll-2;
    scrollbar-width: thin;
  }
  

.custom_scrollbar {
  .scroll-custom-width(6px);
}

.vis-vertical-scroll {
  .scroll-custom-width(6px);
}

/** end scroll **/

/** custom antd table scrollbar */
.ant-table-body {
  overflow: auto !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 @border-radius-scroll @bg-color-scroll-2;
  border-radius: @border-radius-scroll;
  background: @bg-color-scoll-track;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: @bg-color-scroll;
  border-radius: @border-radius-scroll;
}
/** end custom antd table scrollbar */

.app-upload-list-item:hover {
  background-color: #D9D9D9;
}

/** global custom antd */
.ant-form-item {
  margin-bottom: 10px;
}
// .ant-form-item-label {
//   padding-bottom: 3px !important;
//   font-weight: 600;
// }

.team-shifts {
  .ant-form-item-label {
    font-weight: 600;
  }
}
.team-modal {
  .ant-modal-footer {
    margin-top: 0
  }
}
.ant-table-row-first {
  width: 100%;
}
/** end global custom antd */