body,
html,
#root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.image-form {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('./anhlogin.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.form-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.ant-form {
  width: 100%;
}

.ant-btn-primary {
  border-radius: 32px;
  font-size: 17px;
  color: rgba(255, 255, 255, 1);
  height: 50px;
  width: 100%;
  background-color: rgba(9, 109, 217, 1);
  border-color: #1890ff;
}

.ant-form-item-label > label {
  font-size: 16px !important;
  font-weight: 500;
}

.custom-input {
  font-size: 16px;
  border: none;
  border-bottom: 2px solid rgba(38, 38, 38, 1);
  border-radius: 0;
}
.textheader {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
