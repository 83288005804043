/** Scrollable **/
.custom_scrollbar {
  scrollbar-color: #aab2bf #d9d9d9;
  scrollbar-width: thin;
}
.custom_scrollbar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #aab2bf !important;
  border-radius: 10px !important;
}
.custom_scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #d9d9d9 !important;
  border-radius: 10px !important;
  background: #d7d7d7 !important;
}
.vis-vertical-scroll {
  scrollbar-color: #aab2bf #d9d9d9;
  scrollbar-width: thin;
}
.vis-vertical-scroll::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.vis-vertical-scroll::-webkit-scrollbar-thumb {
  background: #aab2bf !important;
  border-radius: 10px !important;
}
.vis-vertical-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #d9d9d9 !important;
  border-radius: 10px !important;
  background: #d7d7d7 !important;
}
/** end scroll **/
/** custom antd table scrollbar */
.ant-table-body {
  overflow: auto !important;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #d9d9d9;
  border-radius: 10px;
  background: #d7d7d7;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #aab2bf;
  border-radius: 10px;
}
/** end custom antd table scrollbar */
.app-upload-list-item:hover {
  background-color: #D9D9D9;
}
/** global custom antd */
.ant-form-item {
  margin-bottom: 10px;
}
.team-shifts .ant-form-item-label {
  font-weight: 600;
}
.team-modal .ant-modal-footer {
  margin-top: 0;
}
.ant-table-row-first {
  width: 100%;
}
/** end global custom antd */
.main {
  height: 100%;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}
.menusWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.top_menu {
  flex: 1;
  overflow-y: auto;
  border-inline-end: none !important;
}
.bottom_menu {
  height: fit-content;
  border-inline-end: none !important;
  box-shadow: -1px -2px 11px -6px #888 !important;
  border-bottom: 1px solid #0000001c;
}
.toggleButton {
  position: absolute;
  right: 0;
  top: 10px;
  transform: translateX(-10%);
  z-index: 2;
}
.avatarContainer {
  display: flex;
  margin: 4px;
  padding-block: 4px;
  max-height: 40px;
  overflow: hidden;
}
.avatarContainer_wrapper {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.avatarContainer_userName {
  color: #0050b3;
  opacity: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 500ms ease-in-out, opacity 500ms ease-in-out 200ms;
}
.avatarContainer_userName.textCollapsed {
  width: 0px;
  display: none;
  opacity: 0;
  transition: width 300ms ease-in-out, opacity 10ms ease-in-out, all 500ms ease-in-out 200ms;
}
.avatarContainer .logoutButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
